<template>
  <div>
    <c-search-box ref="searchbox" :isShowSearch="false" @enter="getList">
      <template slot="search">
        <div class="col-6">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-6">
          <c-dept
            type="search"
            label="점검 대상부서"
            name="targetDeptCd"
            v-model="searchParam.targetDeptCd">
          </c-dept>
        </div>
      </template>
    </c-search-box>
    <!-- 순회점검 목록 -->
    <c-table
      ref="table"
      title="점검항목"
      :filtering="false"
      :columnSetting="false"
      :isFullScreen="false"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      rowKey="saiPatrolResultItemId"
      :selection="popupParam.type"
      @rowDblclick="rowDblclick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getList" />
          <c-btn label="선택" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'patrolMasterPop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
          plantCd: '',
        }
      },
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            sortable: true,
            required: true,
            style: 'width:120px',
          },
          {
            required: true,
            name: 'patrolItemName',
            field: 'patrolItemName',
            label: '점검항목',
            align: 'left',
            sortable: true,
          },
        ],
        data: [],
        height: '500px'
      },
      searchParam: {
        plantCd: null,
        trainingTypeCd: null,
        useFlag: 'Y',
        targetDeptCd: '',
        patrolTypeCd: 'PTC0000002',
      },
      listUrl: '',
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.patrol.resultItem.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '선택된 항목이 없습니다. ', // 선택된 항목이 없습니다. 
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  }
};
</script>
